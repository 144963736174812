// Copyright (C) 2020 Farsight Software Ltd.. All rights reserved.
import React from "react";

import Layout from "../components/layout";
import PageInfo from "../components/pageinfo";

// TODO: This should be a markdown page

export default () => (
    <Layout className="text-padding">
        <PageInfo title="Privacy Policy" />
        <div className="privacy__main">
            <h1>TheNewToys.dev Privacy &amp; Cookie Policy</h1>
            <p className="text--small text--right text--no-margin"><em>Version 1.3, Last Updated: 2020-07-07</em></p>
            <p>
                TheNewToys.dev is a website run by Farsight Software Ltd. This privacy policy
                explains how our TheNewToys.dev uses the personal data we collect from you when you
                use our website and how this site uses browser cookies.
            </p>
            <p>Topics:</p>
            <ul className="privacy__nav">
                <li><a href="#what-data-do-we-collect">What data do we collect?</a></li>
                <li><a href="#how-do-we-collect-your-data">How do we collect your data?</a></li>
                <li><a href="#how-will-we-use-your-data">How will we use your data?</a></li>
                <li><a href="#how-do-we-store-your-data">How do we store your data?</a></li>
                <li><a href="#how-long-do-we-retain-your-data">How long do we retain your data?</a></li>
                <li><a href="#marketing">Marketing</a></li>
                <li><a href="#what-are-your-data-protection-rights">What are your data protection rights?</a></li>
                <li><a href="#what-are-cookies">What are cookies?</a></li>
                <li><a href="#how-do-we-use-cookies">How do we use cookies?</a></li>
                <li><a href="#what-types-of-cookies-do-we-use">What types of cookies do we use?</a></li>
                <li><a href="#how-to-manage-your-cookies">How to manage your cookies</a></li>
                <li><a href="#privacy-policies-of-other-websites">Privacy policies of other websites</a></li>
                <li><a href="#changes-to-our-privacy-policy">Changes to our privacy policy</a></li>
                <li><a href="#how-to-contact-us">How to contact us</a></li>
                <li><a href="#how-to-contact-the-appropriate-authorities">How to contact the appropriate authorities</a>
                </li>
            </ul>
            <h2 id="what-data-do-we-collect">What data do we collect?</h2>
            <p>TheNewToys.dev collects the following data:</p>
            <ul>
                <li>
                    <strong>Standard Web Visit Data</strong>: When you visit pages on TheNewToyw.dev,
                    TheNewToys.dev collects basic technical information related to your request such as:
                    <ul>
                        <li>
                            The IP address of the device requesting the page.
                        </li>
                        <li>
                            A subset of the HTTP headers sent with the request by your web browser,
                            such as the User-Agent header that describes the web browser application
                            you're using to make the request.
                        </li>
                        <li>
                            The date, time, and page requested.
                        </li>
                    </ul>
                </li>
                <li>
                    <strong>Email</strong>: <strong>If</strong> you send email to
                    us on any <code>@thenewtoys.dev</code> address,
                    TheNewToys.dev keeps that email until it is no longer needed.
                </li>
                <li>
                    <strong>Personal Information</strong>: <strong>If</strong> you create an account on
                    TheNewToys.dev, you have your choice of authenticating yourself using your Google,
                    GitHub, or other similar account. TheNewToys.dev will associate that account with
                    your account on TheNewToys.dev and collect the following information from the
                    account you use to sign in:
                    <ul>
                        <li>
                            Your name (according to the account used).
                        </li>
                        <li>
                            Your account name.
                        </li>
                        <li>
                            Your email address.
                        </li>
                    </ul>
                </li>
                <li>
                    <strong>Votes, Comments, Posts, and other
                    contributions</strong> <strong>If</strong> you have a TheNewToys.dev account,
                    you can use on-site features to vote on content, comment on content, or post
                    content; if you do, TheNewToys.dev collects your vote, comment, or posted
                    content and associated it with your account.
                </li>
            </ul>
            <h2 id="how-do-we-collect-your-data">How do we collect your data?</h2>
            <p>
                You directly provide TheNewToys.dev with most of the data we collect.
                We collect data and process data when you:
                <ul>
                    <li>
                        Use or view our website, in which case your browser sends standard technical
                        information.
                    </li>
                    <li>
                        Send us email.
                    </li>
                    <li>
                        Vote on content, post comments, or post content.
                    </li>
                    <li>
                        Complete any surveys or respond to other similar site features.
                    </li>
                </ul>
            </p>
            <p>
                TheNewToys.dev may also receive your data indirectly from the following sources:
                <ul>
                    <li>
                        <strong>If</strong> you create a TheNewToys.dev account authenticating via
                        your Google, GitHub, or other account, the provider of that account sends
                        us basic information about the account.
                    </li>
                </ul>
            </p>
            <h2 id="how-will-we-use-your-data">How will we use your data?</h2>
            <p>TheNewToys.dev collects your data so that we can:</p>
            <ul>
                <li>
                    Comply with any legal requirements.
                </li>
                <li>
                    Ensure that the TheNewToys.dev website operates smoothly.
                </li>
                <li>
                    Provide support in response to email.
                </li>
                <li>
                    Associate your votes, comments, or posted content with your account.
                </li>
                <li>
                    Show your votes (in aggregate form only) to other users of TheNewToys.dev.
                </li>
                <li>
                    Show your comments and other posted content to other users of TheNewToys.dev,
                    linked to your account.
                </li>
            </ul>
            <p>
                TheNewToys.dev uses the following partner companies to store and process your data:
                <ul>
                    <li>Vercel, Inc. (<a href="https://vercel.com/home" _target="blank">https://vercel.com/home</a>)
                        <br />340 S Lemon Ave #4133
                        <br />Walnut, CA 91789
                        <br />USA
                    </li>
                    <li>Amazon Web Services EMEA SARL.
                        <br />38 Avenue John F. Kennedy
                        <br />L-1855
                        <br />Luxembourg
                    </li>
                    <li>
                        Conva Ventures Inc., aka Fathom Analytics
                        (<a href="https://usefathom.com">https://usefathom.com</a>).
                        <br />BOX 37058
                        <br />Millstream PO,
                        <br />Victoria, BC
                        <br />V9B 0E8
                        <br />Canada
                    </li>
                </ul>
            </p>
            <h2 id="how-do-we-store-your-data">How do we store your data?</h2>
            <p>
                TheNewToys.dev securely stores your data using the secure facilities of Vercel, Inc.,
                Amazon Web Services EMEA SARL, and Conva Ventures Inc. in the United States of
                America (USA), Canada, Countries within Europe (including the United Kingdom of
                Great Britain and Northern Ireland) and the Middle East.
            </p>
            <h2 id="how-long-do-we-retain-your-data">How long do we retain your data?</h2>
            <p>
                We determine how long to retain your data based on:
                <ul>
                    <li>The amount, nature, and sensitivity of your data;</li>
                    <li>The reasons for which we collect and process your data;</li>
                    <li>The length of time we have an ongoing relationship with you and provide you
                    with access to TheNewToys.dev; and</li>
                    <li>applicable legal requirements.</li>
                </ul>
            </p>
            <p>
                We retain your data for as long as needed to provide our services.
                If you have an active account with us, we retain essential information about that
                account, but regularly delete other information that is less essential to the provision
                of our Services in order to minimize our storage of data.
            </p>
            <p>
                We also will retain personal data or information that we've collected from you where we
                have an ongoing legitimate business need to do so (for example, to comply with
                applicable legal, tax or accounting requirements). Additionally, we cannot delete
                information when it is needed for the establishment, exercise or defense of legal
                claims (also known as a "litigation hold"). In this case, the information must be
                retained as long as needed for exercising respective potential legal claims.
            </p>
            <p>
                When we no longer have an ongoing legitimate requirement to process your data, we will
                either delete or anonymize it or, if this is not possible for any reasons (for example,
                because your data has been stored in backup archives), we will securely store your data
                and isolate it from any further processing until deletion is possible.
            </p>
            <p>
                If you have any questions about data retention, please contact us
                at <a href="mailto:privacy@thenewtoys.dev">privacy@thenewtoys.dev</a>.
            </p>
            <h2 id="marketing">Marketing</h2>
            <p>
                TheNewToys.dev does not currently send marketing information to visitors or account
                holders. If and when we do, you will have the opportunity to opt into marketing
                communications; if you don't, you won't receive them.
            </p>
            <h2 id="what-are-your-data-protection-rights">What are your data protection rights?</h2>
            <p>
                TheNewToys.dev would like to make sure you are fully aware of all of your data
                protection rights. Every user is entitled to the following:
            </p>
            <p>
                The right to access&nbsp;&mdash; You have the right to request copies of your personal
                data from TheNewToys.dev. We may charge you a small fee for this service.
            </p>
            <p>
                The right to rectification&nbsp;&mdash; You have the right to request that
                TheNewToys.dev correct any information you believe is inaccurate.
                You also have the right to request that TheNewToys.dev complete information you believe
                is incomplete.
            </p>
            <p>
                The right to erasure&nbsp;&mdash; You have the right to request that TheNewToys.dev
                erase your personal data, under certain conditions.
            </p>
            <p>
                The right to restrict processing&nbsp;&mdash; You have the right to request that
                TheNewToys.dev restrict the processing of your personal data, under certain
                conditions.
            </p>
            <p>
                The right to object to processing&nbsp;&mdash; You have the right to object to
                TheNewToys.dev’s processing of your personal data, under certain conditions.
            </p>
            <p>
                The right to data portability&nbsp;&mdash; You have the right to request that
                TheNewToys.dev transfer the data that we have collected to another organization, or
                directly to you, under certain conditions.
            </p>
            <p>
                If you make a request, we have one month to respond to you. If you would like to
                exercise any of these rights, please <a href="#how-to-contact-us">contact us</a>.
            </p>
            <h2 id="what-are-cookies">What are Cookies?</h2>
            <p>
                Cookies are small text files placed on your computer by a website in response to a
                visit to a web page which are then sent back to that website when you visit
                pages subsequently.
            </p>
            <p>
                For further information, visit <a target="_blank" href="https://allaboutcookies.org/">allaboutcookies.org</a>.
            </p>
            <h2 id="how-do-we-use-cookies">How do we use cookies?</h2>
            <p>
                TheNewToys.dev does not currently use cookies at all. However, in the future we may
                use cookies in a range of ways to improve your experience on our website, including:
            </p>
            <ul>
                <li>Keeping you signed in</li>
                <li>Understanding how you use our website</li>
            </ul>
            <h2 id="what-types-of-cookies-do-we-use">What types of cookies do we use?</h2>
            <p>
                TheNewToys.dev does not currently use cookies at all. However, in the future we may
                use cookies for:
            </p>
            <ul>
                <li>
                    Functionality&nbsp;&mdash; In the future, TheNewToys.dev may use these cookies so
                    that we recognize you on our website and remember your previously selected
                    preferences. These could include what language you prefer and location you are in.
                    A mix of first-party and third-party cookies may be used.
                </li>
                <li>
                    Advertising&nbsp;&mdash; In the future, TheNewToys.dev may use these cookies to
                    collect information about your visit to our website, the content you viewed, the
                    links you followed, and similar information. In the future, TheNewToys.dev may
                    sometimes share some limited aspects of this data with third parties for advertising
                    purposes. In he future, we may also share online data collected through cookies
                    with our advertising partners. This means that when you visit another website, you
                    may be shown advertising based on your browsing patterns on our website.
                </li>
            </ul>
            <h2 id="how-to-manage-your-cookies">How to manage your cookies</h2>
            <p>
                You can set your browser not to accept cookies,
                and <a target="_blank" href="https://allaboutcookies.org/">allaboutcookies.org</a> tells
                you how to remove cookies from your browser. However, in the future, in a few cases,
                some of our website features may not function as a result.
            </p>
            <h2 id="privacy-policies-of-other-websites">Privacy policies of other websites</h2>
            <p>
                The TheNewToys.dev website contains links to other websites. Our privacy policy applies
                only to our website, so if you click on a link to another website, you should read their
                privacy policy.
            </p>
            <h2 id="changes-to-our-privacy-policy">Changes to our privacy policy</h2>
            <p>
                TheNewToys.dev keeps its privacy policy under regular review and places any updates
                on this web page. This privacy policy was last updated on 2nd July 2020.
            </p>
            <h2 id="how-to-contact-us">How to contact us</h2>
            <p>
                If you have any questions about TheNewToys.dev’s privacy policy, the data we hold on
                you, or you would like to exercise one of your data protection rights, please do not
                hesitate to contact at <a href="mailto:privacy@thenewtoys.dev">privacy@thenewtoys.dev</a>
                or write to us:
                <br />
                <br />Privacy Officer
                <br />Farsight Software Ltd
                <br />20-22 Wenlock Road
                <br />London, N1 7GU
                <br />United Kingdom
            </p>
            <h2 id="how-to-contact-the-appropriate-authorities">How to contact the appropriate authorites</h2>
            <p>
                Should you wish to report a complaint or if you feel that TheNewToys.dev has not
                addressed your concern in a satisfactory manner, you may contact
                the <a target="_blank" href="https://ico.org.uk/">Information Commissioner’s Office</a>.
            </p>
            <div style={{ minHeight: "70vh" }} />
        </div>
    </Layout>
);